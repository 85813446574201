import PageTitle from "components/PageTitle";
import UpdateUserInformation from "pages/settings/UpdateUserInformationForm";
import History from "pages/settings/History";
import { StyledContainer } from "styles/pages/settings";

const Settings = () => (
  <StyledContainer>
    <PageTitle title="Settings" />
    <UpdateUserInformation />
    <History />
  </StyledContainer>
);

export default Settings;
