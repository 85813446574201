import _ from "lodash";
import PropTypes from "prop-types";
import TextField from "@mui/material/TextField";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogActions from "@mui/material/DialogActions";
import Button from "@mui/material/Button";

const AddNewOptionDialog = ({
  open,
  handleClose,
  handleSubmit,
  dialogValue,
  setDialogValue,
  label,
  title,
  subTitle,
  optionValidation,
}) => {
  const disabled = _.isFunction(optionValidation)
    ? !optionValidation(dialogValue)
    : !dialogValue;

  return (
    <Dialog open={open} onClose={handleClose} fullWidth>
      <form onSubmit={handleSubmit}>
        <DialogTitle>{title}</DialogTitle>
        <DialogContent>
          <DialogContentText>{subTitle}</DialogContentText>
          <TextField
            autoFocus
            margin="dense"
            value={dialogValue}
            onChange={(event) => setDialogValue(event.target.value)}
            label={label}
            type="text"
            onKeyDown={(e) => {
              if (e?.key === "Enter" && !disabled) {
                handleSubmit();
              }
            }}
            sx={{ width: "100%", marginTop: "20px" }}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
          <Button disabled={disabled} type="submit">
            Add
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
};

AddNewOptionDialog.propTypes = {
  open: PropTypes.bool,
  handleClose: PropTypes.func,
  handleSubmit: PropTypes.func,
  dialogValue: PropTypes.string,
  title: PropTypes.string,
  subTitle: PropTypes.string,
  setDialogValue: PropTypes.func,
  optionValidation: PropTypes.func,
  label: PropTypes.string,
};

AddNewOptionDialog.defaultProps = {
  open: false,
  handleClose: () => {},
  handleSubmit: () => {},
  dialogValue: "",
  setDialogValue: () => {},
  title: "",
  subTitle: "",
  optionValidation: null,
  label: "",
};

export default AddNewOptionDialog;
