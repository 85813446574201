import PropTypes from "prop-types";
import _ from "lodash";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";

const CustomCheckbox = ({ checked, onChange, label, ...rest }) => (
  <FormControlLabel
    control={
      <Checkbox
        checked={checked}
        onChange={(e) => _.isFunction(onChange) && onChange(e.target.checked)}
      />
    }
    label={label}
    {...rest}
  />
);

CustomCheckbox.propTypes = {
  label: PropTypes.string,
  checked: PropTypes.bool,
  onChange: PropTypes.func,
};

CustomCheckbox.defaultProps = {
  label: "",
  checked: false,
  onChange: (v) => v,
};

export default CustomCheckbox;
