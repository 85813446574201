import PropTypes from "prop-types";
import moment from "moment";
import Avatar from "@mui/material/Avatar";
import Typography from "@mui/material/Typography";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import PageTitle from "components/PageTitle";
import { PROP_TYPES, DEFAULT_STATE } from "pages/order/prop-types";
import { OrderInfoContainer, StepperLabel } from "styles/pages/order";
import { StepContent } from "@mui/material";

const StepIcon = ({ src }) => (
  <Avatar sx={{ border: "1px solid #a1a1a1" }} alt="profile" src={src} />
);

const OrderHistory = ({ state }) => {
  return (
    <OrderInfoContainer>
      <PageTitle title="Order History" />

      <Stepper orientation="vertical">
        {state?.orderEvents?.map((step) => (
          <Step key={`${step.timeStamp}_${step?.event}_${step?.name}`} active>
            <StepLabel
              StepIconProps={{ src: step?.profileImg }}
              StepIconComponent={StepIcon}
            >
              <StepperLabel>
                <div>{step.event}</div>
                <div className="MuiTypography-root">
                  {moment.unix(step.timeStamp).format("MMM DD,YYYY | hh:mm A")}
                </div>
              </StepperLabel>
            </StepLabel>
            <StepContent>
              <Typography>{step?.name}</Typography>
            </StepContent>
          </Step>
        ))}
      </Stepper>
    </OrderInfoContainer>
  );
};

StepIcon.propTypes = {
  src: PropTypes.string,
};

StepIcon.defaultProps = {
  src: "",
};

OrderHistory.propTypes = { ...PROP_TYPES };
OrderHistory.defaultProps = { ...DEFAULT_STATE };

export default OrderHistory;
