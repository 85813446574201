import { useSelector } from "react-redux";
import Avatar from "@mui/material/Avatar";

const StepIcon = () => {
  const user = useSelector((state) => state?.user);

  return <Avatar alt="profile" src={user?.profileImg} />;
};

export default StepIcon;
