import PropTypes from "prop-types";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import OutlinedInput from "@mui/material/OutlinedInput";
import InputAdornment from "@mui/material/InputAdornment";
import SearchIcon from "@mui/icons-material/Search";
import IconButton from "@mui/material/IconButton";

const SearchInput = ({ label, value, handleChange, handleSubmit }) => (
  <FormControl sx={{ width: "100%" }}>
    <InputLabel size="small">{label}</InputLabel>
    <OutlinedInput
      label={label}
      size="small"
      value={value}
      onChange={(event) => handleChange(event?.target?.value)}
      onKeyDown={(e) => {
        if (e?.key === "Enter") {
          handleSubmit();
        }
      }}
      endAdornment={
        <InputAdornment position="end">
          <IconButton
            onClick={handleSubmit}
            onMouseDown={(event) => event.preventDefault()}
            edge="end"
          >
            <SearchIcon />
          </IconButton>
        </InputAdornment>
      }
    />
  </FormControl>
);

SearchInput.propTypes = {
  label: PropTypes.string.isRequired,
  value: PropTypes.string,
  handleChange: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
};

SearchInput.defaultProps = {
  value: "",
};

export default SearchInput;
