import PropTypes from "prop-types";
import StyledLoadingButton from "styles/components/loading-button";

const Button = ({ label, ...rest }) => (
  <StyledLoadingButton {...rest}>{label}</StyledLoadingButton>
);

Button.propTypes = {
  label: PropTypes.string,
};

Button.defaultProps = {
  label: "",
};

export default Button;
