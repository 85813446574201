import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import moment from "moment";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import StepContent from "@mui/material/StepContent";
import Typography from "@mui/material/Typography";
import { usePost } from "hooks/usePost";
import { CONFLUENCE_USER_EVENTS } from "constants/services";
import PageTitle from "components/PageTitle";
import Pagination from "components/Pagination";
import StepIcon from "pages/settings/StepIcon";
import { HistoryWrapper, StepperLabel } from "styles/pages/settings";

const take = 5;

const History = () => {
  const user = useSelector((state) => state?.user);
  const { mutateAsync } = usePost();
  const [data, setData] = useState();
  const [page, setPage] = useState(1);

  const fetchUserHistory = async () => {
    const { data: history } = await mutateAsync({
      url: CONFLUENCE_USER_EVENTS,
      data: { take, skip: (page - 1) * take },
    });
    setData(history);
  };

  useEffect(() => {
    fetchUserHistory();
  }, [page]);

  useEffect(() => {
    if (page === 1) {
      fetchUserHistory();
    } else {
      setPage(1);
    }
  }, [user]);

  return (
    <HistoryWrapper>
      <PageTitle title="Events History" />
      {data && (
        <div className="pagination">
          <Pagination
            count={Math.ceil(data.totalCount / take)}
            page={page}
            handleChange={(e, v) => setPage(v)}
          />
        </div>
      )}
      <Stepper orientation="vertical">
        {data?.data.map((step) => {
          const os = step?.device?.os;
          const client = step?.device?.client;
          const device = step?.device?.device;
          return (
            <Step key={step.timeStamp} active>
              <StepLabel StepIconComponent={StepIcon}>
                <StepperLabel>
                  <div>{step.eventName}</div>
                  <div className="MuiTypography-root">
                    {moment
                      .unix(step.timeStamp)
                      .format("MMM DD,YYYY | hh:mm A")}
                  </div>
                </StepperLabel>
              </StepLabel>
              <StepContent>
                {!!os?.name && (
                  <Typography>
                    {os?.name}&nbsp;({os?.version})
                  </Typography>
                )}
                {!!client?.name && (
                  <Typography>
                    {client?.name}&nbsp;({client?.version})
                  </Typography>
                )}
                {!!device?.brand && (
                  <Typography>
                    {device?.brand}&nbsp;({device?.type})
                  </Typography>
                )}
              </StepContent>
            </Step>
          );
        })}
      </Stepper>
    </HistoryWrapper>
  );
};

export default History;
