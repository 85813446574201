import CircularProgress from "@mui/material/CircularProgress";
import { Container, Wrapper } from "styles/components/progress";

const Progress = () => (
  <Container>
    <Wrapper>
      <CircularProgress size={50} />
    </Wrapper>
  </Container>
);

export default Progress;
