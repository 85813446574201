import { useState } from "react";
import Button from "@mui/material/Button";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import OrderEstimationConfirmation from "pages/order/OrderEstimationConfirmation";
import CloseIcon from "@mui/icons-material/Close";
import { PROP_TYPES, DEFAULT_STATE } from "pages/order/prop-types";

const OrderEstimationDialogHeader = ({
  state,
  setState,
  updateOrderEstimate,
  createEstimate,
  // quickBooksConnect,
  // generatePdf,
}) => {
  const [open, setOpen] = useState(false);
  const handleClose = () =>
    setState({
      ...state,
      orderEstimationDialog: false,
      editedLineItems: [],
      editedOtherCharges: [],
    });

  const estimateData = state?.estimateData;
  const qbCutomers = estimateData?.qbCutomers;
  const shipAddress = estimateData?.shipAddress;
  const editedLineItems = estimateData?.editedLineItems;
  const disabled =
    !qbCutomers?.Customer ||
    !shipAddress?.shipName ||
    !shipAddress?.address1 ||
    !shipAddress?.city ||
    !shipAddress?.state ||
    !shipAddress?.country ||
    !shipAddress?.zip ||
    !!editedLineItems?.find(
      (e) => !e?.qbItemInfo?.QueryResponse?.Item?.length
    ) ||
    !estimateData?.qbSalesCode;

  return (
    <AppBar sx={{ position: "relative", background: "#2ca01c" }}>
      <Toolbar>
        <IconButton
          edge="start"
          color="inherit"
          // onClick={handleClose}
          onClick={() => setOpen(true)}
          aria-label="close"
        >
          <CloseIcon />
        </IconButton>
        <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
          Estimate
        </Typography>

        <div style={{ flex: 1 }}>
          <p>QuickBooks Estimate</p>
          {/* <Button
            color="inherit"
            onClick={quickBooksConnect}
            style={{
              color: "white",
              backgroundColor: "green",
              borderRadius: "4px",
            }}
          >
            QuickBooks Connect
          </Button> */}
        </div>

        {!state?.estimateData?.invoice && (
          <>
            <Button color="inherit" onClick={updateOrderEstimate}>
              Save
            </Button>
            <Button
              color="inherit"
              onClick={createEstimate}
              disabled={disabled}
            >
              Create Estimate
            </Button>
          </>
        )}

        {/* <Button color="inherit" onClick={generatePdf}>
          Generate PDF
        </Button>
         */}
      </Toolbar>

      {open && (
        <OrderEstimationConfirmation
          open={open}
          setOpen={setOpen}
          agree={() => {
            setOpen(false);
            handleClose();
          }}
          disagree={() => setOpen(false)}
        />
      )}
    </AppBar>
  );
};

OrderEstimationDialogHeader.propTypes = PROP_TYPES;

OrderEstimationDialogHeader.defaultProps = DEFAULT_STATE;

export default OrderEstimationDialogHeader;
