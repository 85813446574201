import PropTypes from "prop-types";

const PROP_TYPES = {
  state: PropTypes.shape({
    showCreateQuoteRequestDialog: PropTypes.bool,
  }),
  setState: PropTypes.func,
};

const DEFAULT_STATE = {
  state: {
    showCreateQuoteRequestDialog: false,
  },
  setState: (v) => v,
};

export { PROP_TYPES, DEFAULT_STATE };
