import moment from "moment";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import { usePost } from "hooks/usePost";
import TextFieldInput from "components/TextFieldInput";
import Autocomplete from "components/Autocomplete";
import { PROP_TYPES, DEFAULT_STATE } from "pages/order/prop-types";
import {
  QUICK_BOOKS_ITEMS,
  CONFLUENCE_PURCHASE_ORDER,
} from "constants/services";
import { LineItem } from "styles/pages/order";

const AddQBLineItem = ({ state, setState }) => {
  const { mutateAsync } = usePost();

  const handleClose = () =>
    setState({ ...state, showQBLineItemDialog: { visibility: false } });

  const showQBLineItemDialog = state?.showQBLineItemDialog;
  const payload = showQBLineItemDialog?.payload;

  const handlePayloadChange = (key, value) =>
    setState({
      ...state,
      showQBLineItemDialog: {
        ...state?.showQBLineItemDialog,
        payload: {
          ...state?.showQBLineItemDialog?.payload,
          [key]: value,
        },
      },
    });

  const fetchPurchaseOrderInfo = async (queryResponse) => {
    const { data } = await mutateAsync({
      url: CONFLUENCE_PURCHASE_ORDER,
      data: {
        orderNumber: state?.orderNumber,
        vendorName: state?.vendorName,
      },
    });

    setState({
      ...state,
      estimateData: {
        ...data,
        invoiceDate: data?.invoiceDate || moment().unix(),
        invoiceTerms: data?.invoiceTerms || "Net 45",
        invoiceDueDate: data?.invoiceDueDate || moment().add(45, "days").unix(),
      },
      editedLineItems: state?.editedLineItems?.map((l, j) => {
        if (state?.showQBLineItemDialog?.lineItemIndex !== j) return l;
        return {
          ...l,
          qbItemInfo: {
            QueryResponse: { Item: [queryResponse?.Item] },
          },
          rate: queryResponse?.Item?.UnitPrice,
        };
      }),
      showQBLineItemDialog: { visibility: false },
    });
  };

  const disabled =
    !payload?.Name ||
    payload?.UnitPrice < 0 ||
    !payload?.Type?.id ||
    !payload?.SalesTaxCodeRef?.id;

  return (
    <Dialog
      open={state?.showQBLineItemDialog?.visibility}
      onClose={handleClose}
      maxWidth="md"
    >
      <DialogTitle>Quick Books Line Item</DialogTitle>

      <DialogContent>
        <LineItem>
          <TextFieldInput
            value={payload?.Name}
            label="Name"
            handleChange={(e) => handlePayloadChange("Name", e?.target?.value)}
          />

          <TextFieldInput
            value={payload?.UnitPrice}
            label="Unit Price"
            type="number"
            handleChange={(e) =>
              handlePayloadChange("UnitPrice", Number(e?.target?.value))
            }
          />
        </LineItem>
        <LineItem>
          <Autocomplete
            label="Type"
            value={payload?.Type}
            setValue={(c) => handlePayloadChange("Type", c)}
            inputValue={payload?.TypeInputValue}
            setInputValue={(v) => handlePayloadChange("TypeInputValue", v)}
            options={[
              { id: "Service", label: "Service" },
              { id: "NonInventory", label: "NonInventory" },
            ]}
          />

          <Autocomplete
            label="Sales Tax Code"
            value={payload?.SalesTaxCodeRef}
            setValue={(v) =>
              setState({
                ...state,
                showQBLineItemDialog: {
                  ...state?.showQBLineItemDialog,
                  payload: {
                    ...state?.showQBLineItemDialog?.payload,
                    SalesTaxCodeRef: v,
                  },
                },
              })
            }
            inputValue={payload?.SalesTaxCodeRefValue}
            setInputValue={(v) =>
              handlePayloadChange("SalesTaxCodeRefValue", v)
            }
            options={state?.qbSalesCodes?.map((q) => ({
              ...q,
              id: q?.Id,
              label: q?.DisplayName,
            }))}
          />
        </LineItem>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose}>Cancel</Button>
        <Button
          variant="contained"
          onClick={async () => {
            const finalPayload = {
              Name: payload?.Name?.replace(/[^\w\s]/gi, ""),
              UnitPrice: payload?.UnitPrice,
              Type: payload?.Type?.id,
              SalesTaxCodeRef: {
                value: payload?.SalesTaxCodeRef?.id,
              },
            };

            const { data } = await mutateAsync({
              url: `${QUICK_BOOKS_ITEMS}?qbCountry=${state?.printProvider?.id}`,
              data: finalPayload,
              isPut: true,
            });

            if (data) {
              await fetchPurchaseOrderInfo(data);
            }
          }}
          disabled={disabled}
        >
          Add
        </Button>
      </DialogActions>
    </Dialog>
  );
};

AddQBLineItem.propTypes = PROP_TYPES;

AddQBLineItem.defaultProps = DEFAULT_STATE;

export default AddQBLineItem;
