import { useEffect } from "react";
import Divider from "@mui/material/Divider";
import moment from "moment";
import TextFieldInput from "components/TextFieldInput";
import Datepicker from "components/Datepicker";
import TagsAutocomplete from "components/TagsAutocomplete";
import { PROP_TYPES, DEFAULT_STATE } from "pages/order/prop-types";
import { BOT_CA_ADDRESS, BOT_USA_ADDRESS } from "constants/address";
import { QUICK_BOOKS_CUSTOMER } from "constants/services";
import { usePost } from "hooks/usePost";
import {
  StyledSubHeader,
  StyledSubHeader1,
  Title,
  InvoiceDetails,
  Address,
  ShipTo,
  Row,
} from "styles/pages/order";

const OrderEstimationSubHeaderDialog = ({ state, setState }) => {
  const { mutateAsync } = usePost();
  const printProvider = state?.printProvider?.id;
  const company = printProvider === "CA" ? BOT_CA_ADDRESS : BOT_USA_ADDRESS;
  const estimateData = state?.estimateData;
  const shipAddress = estimateData?.shipAddress;

  const estimateHandleChange = (value, key) =>
    setState({
      ...state,
      estimateData: { ...estimateData, [key]: value },
    });

  const estimateAddressHandleChange = (value, key) =>
    setState({
      ...state,
      estimateData: {
        ...estimateData,
        shipAddress: { ...shipAddress, [key]: value },
      },
    });

  useEffect(() => {
    if (!state?.estimateData?.invoiceDate) {
      setState({
        ...state,
        estimateData: { ...estimateData, invoiceDate: moment().unix() },
      });
    }
  }, [state?.estimateData?.invoiceDate]);

  return (
    <InvoiceDetails>
      <StyledSubHeader>
        <div>
          <Title>{company?.comapnyName}</Title>
          <div className="company-address-line-one">
            {company?.addressLineOne}
          </div>
          <div className="company-address">
            {company?.city}&nbsp;{company?.state}&nbsp;{company?.postalCode}
          </div>

          <div className="company-email">{company?.email}</div>
        </div>

        <div>
          <img alt="company-logo" src={company?.companyLogoUrl} width="200" />
        </div>
      </StyledSubHeader>
      <Title marginBottom>Estimate</Title>
      <StyledSubHeader1>
        <ShipTo style={{ flex: 2 }}>
          <Title fontSize={16}>QuickBooks Customer</Title>
          <Address>
            <Row>
              <TagsAutocomplete
                optionApiKey="DisplayName"
                apiEndPoint={`${QUICK_BOOKS_CUSTOMER}?qbCountry=${state?.printProvider?.id}`}
                value={
                  estimateData?.qbCutomers?.Customer
                    ? {
                        title: estimateData?.qbCutomers?.Customer?.DisplayName,
                        value: estimateData?.qbCutomers?.Customer?.DisplayName,
                      }
                    : { title: "", value: "" }
                }
                setValue={async (v) => {
                  let response;
                  if (v?.Id) {
                    const { data } = await mutateAsync({
                      url: `${QUICK_BOOKS_CUSTOMER}?qbCountry=${state?.printProvider?.id}`,
                      data: { customerId: v?.Id },
                    });
                    response = data;
                  }

                  setState({
                    ...state,
                    estimateData: { ...estimateData, qbCutomers: response },
                  });
                }}
                label="QuickBooks Customers"
                multiple={false}
              />
            </Row>
          </Address>
        </ShipTo>

        <ShipTo>
          <Title fontSize={16}>SHIP TO</Title>
          <Address>
            <Row>
              <TextFieldInput
                value={shipAddress?.shipName}
                label="Name"
                handleChange={(e) =>
                  estimateAddressHandleChange(e?.target?.value, "shipName")
                }
              />
            </Row>

            <Row>
              <TextFieldInput
                value={shipAddress?.address1}
                label="Address1"
                handleChange={(e) =>
                  estimateAddressHandleChange(e?.target?.value, "address1")
                }
              />
              <TextFieldInput
                value={shipAddress?.address2}
                label="Address2"
                handleChange={(e) =>
                  estimateAddressHandleChange(e?.target?.value, "address2")
                }
              />
            </Row>

            <Row>
              <TextFieldInput
                value={shipAddress?.city}
                label="City"
                handleChange={(e) =>
                  estimateAddressHandleChange(e?.target?.value, "city")
                }
              />
              <TextFieldInput
                value={shipAddress?.state}
                label="State"
                handleChange={(e) =>
                  estimateAddressHandleChange(e?.target?.value, "state")
                }
              />
              <TextFieldInput
                value={shipAddress?.country}
                label="Country"
                handleChange={(e) =>
                  estimateAddressHandleChange(e?.target?.value, "country")
                }
              />
            </Row>

            <TextFieldInput
              value={shipAddress?.zip}
              label="Zip"
              handleChange={(e) =>
                estimateAddressHandleChange(e?.target?.value, "zip")
              }
            />
          </Address>
        </ShipTo>

        <ShipTo>
          <Title fontSize={16}>INVOICE DETAILS</Title>
          <Address>
            <Row>
              <TextFieldInput
                value={estimateData?.invoice}
                label="Invoice"
                disabled
                handleChange={(e) =>
                  estimateHandleChange(e?.target?.value, "invoice")
                }
              />
            </Row>
            <Row>
              <Datepicker
                label="Invoice Date"
                value={
                  estimateData?.invoiceDate
                    ? moment.unix(estimateData?.invoiceDate)
                    : null
                }
                handleChange={(e) =>
                  estimateHandleChange(e?.unix(), "invoiceDate")
                }
              />
            </Row>
            <Row>
              <Datepicker
                label="Invoice Due Date"
                value={
                  estimateData?.invoiceDueDate
                    ? moment.unix(estimateData?.invoiceDueDate)
                    : null
                }
                handleChange={(e) =>
                  estimateHandleChange(e?.unix(), "invoiceDueDate")
                }
              />
            </Row>
            <Row>
              <TextFieldInput
                value={estimateData?.invoiceTerms || ""}
                label="Terms"
                handleChange={(e) =>
                  estimateHandleChange(e?.target?.value, "invoiceTerms")
                }
              />
            </Row>
          </Address>
        </ShipTo>
      </StyledSubHeader1>

      <Divider sx={{ marginBottom: "20px" }} />

      <Row>
        <TextFieldInput
          label="Ship Via"
          value={estimateData?.shipVia || ""}
          handleChange={(e) =>
            estimateHandleChange(e?.target?.value, "shipVia")
          }
        />

        <TextFieldInput
          label="PO Number"
          value={estimateData?.poNumber}
          handleChange={(e) =>
            estimateHandleChange(e?.target?.value, "poNumber")
          }
        />
      </Row>
    </InvoiceDetails>
  );
};

OrderEstimationSubHeaderDialog.propTypes = PROP_TYPES;

OrderEstimationSubHeaderDialog.defaultProps = DEFAULT_STATE;

export default OrderEstimationSubHeaderDialog;
