import PropTypes from "prop-types";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";

const OrderEstimationConfirmation = ({ open, setOpen, agree, disagree }) => {
  const handleClose = () => setOpen(false);

  return (
    <Dialog open={open} onClose={handleClose}>
      <DialogTitle>Warning</DialogTitle>
      <DialogContent>
        <DialogContentText>
          Please make sure you save the progress before closing the estimate.
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={disagree}>No</Button>
        <Button onClick={agree}>Yes, already saved</Button>
      </DialogActions>
    </Dialog>
  );
};

OrderEstimationConfirmation.propTypes = {
  open: PropTypes.bool,
  setOpen: PropTypes.func,
  agree: PropTypes.func,
  disagree: PropTypes.func,
};

OrderEstimationConfirmation.defaultProps = {
  open: false,
  setOpen: () => {},
  agree: () => {},
  disagree: () => {},
};

export default OrderEstimationConfirmation;
