import { useState, useEffect } from "react";
import PropTypes from "prop-types";
import TextField from "@mui/material/TextField";
import Autocomplete, { createFilterOptions } from "@mui/material/Autocomplete";
import AddNewOptionDialog from "components/TagsAutocomplete/AddNewOptionDialog";
import { useFetch } from "hooks/useFetch";
import { usePost } from "hooks/usePost";
import Container from "styles/components/tagsautocomplete";

const filter = createFilterOptions();

const TagsAutocomplete = ({
  apiEndPoint,
  optionApiKey,
  title,
  subTitle,
  value,
  setValue,
  optionValidation,
  label,
  createable,
  hardFetchOptions,
  multiple,
  ...rest
}) => {
  const { refetch } = useFetch(apiEndPoint, apiEndPoint, {
    enabled: false,
  });
  const { mutateAsync: addNewOption } = usePost();
  const [autocompleteOptions, setAutocompleteOptions] = useState([]);
  const [open, toggleOpen] = useState(false);
  const [dialogValue, setDialogValue] = useState("");

  const fetchOptions = async () => {
    const { data } = await refetch();

    if (data) {
      if (!autocompleteOptions?.length || hardFetchOptions) {
        setAutocompleteOptions(
          data?.map((d) => ({
            ...d,
            title: d[optionApiKey],
            value: d[optionApiKey],
          }))
        );
      } else {
        let newOptions = data?.filter(
          (d) => !autocompleteOptions?.find((a) => d[optionApiKey] === a.value)
        );

        newOptions = newOptions?.map((d) => ({
          title: d[optionApiKey],
          value: d[optionApiKey],
        }));

        setAutocompleteOptions([...autocompleteOptions, ...newOptions]);
      }
    }
  };

  useEffect(() => {
    if (apiEndPoint) {
      fetchOptions();
    }
  }, [apiEndPoint]);

  useEffect(() => {
    fetchOptions();
  }, [hardFetchOptions]);

  const handleClose = () => {
    setDialogValue("");
    toggleOpen(false);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    const { data } = await addNewOption({
      url: apiEndPoint,
      data: { [optionApiKey]: dialogValue },
    });

    if (data?.success) {
      await fetchOptions();
    }

    handleClose();
  };

  return (
    <Container>
      <Autocomplete
        size="small"
        multiple={multiple}
        label={label}
        value={value}
        isOptionEqualToValue={(option, v) => option.value === v.value}
        onChange={(e, v) => {
          if (v) {
            const newValue = v[v.length - 1];
            if (typeof newValue === "string") {
              // timeout to avoid instant validation of the dialog's form.
              setTimeout(() => {
                toggleOpen(true);
                setDialogValue(newValue);
              });
            } else if (newValue && newValue.inputValue) {
              toggleOpen(true);
              setDialogValue(newValue.inputValue);
            } else {
              setValue(v);
            }
          } else {
            setValue(v);
          }
        }}
        filterOptions={(options, params) => {
          const filtered = filter(options, params);

          if (params.inputValue !== "" && createable) {
            filtered.push({
              inputValue: params.inputValue,
              title: `Add "${params.inputValue}"`,
            });
          }

          return filtered;
        }}
        options={autocompleteOptions}
        getOptionLabel={(option) => {
          // e.g value selected with enter, right from the input
          if (typeof option === "string") {
            return option;
          }
          if (option.inputValue) {
            return option.inputValue;
          }
          return option.title;
        }}
        selectOnFocus
        clearOnBlur
        handleHomeEndKeys
        renderOption={(props, option) => <li {...props}>{option.title}</li>}
        renderInput={(params) => <TextField {...params} label={label} />}
        {...rest}
      />
      <AddNewOptionDialog
        open={open}
        handleSubmit={handleSubmit}
        dialogValue={dialogValue}
        setDialogValue={setDialogValue}
        handleClose={handleClose}
        title={title}
        subTitle={subTitle}
        optionValidation={optionValidation}
        label={label}
      />
    </Container>
  );
};

TagsAutocomplete.propTypes = {
  apiEndPoint: PropTypes.string.isRequired,
  optionApiKey: PropTypes.string.isRequired,
  multiple: PropTypes.bool,
  title: PropTypes.string,
  subTitle: PropTypes.string,
  optionValidation: PropTypes.func,
  createable: PropTypes.bool,
  hardFetchOptions: PropTypes.bool,
  value: PropTypes.oneOfType([
    PropTypes.arrayOf(
      PropTypes.shape({
        title: PropTypes.string,
        value: PropTypes.string,
      })
    ),
    PropTypes.shape({
      title: PropTypes.string,
      value: PropTypes.string,
    }),
  ]),
  setValue: PropTypes.func,
  label: PropTypes.string,
};

TagsAutocomplete.defaultProps = {
  title: "",
  subTitle: "",
  value: [],
  createable: false,
  setValue: () => {},
  optionValidation: null,
  label: "",
  hardFetchOptions: false,
  multiple: true,
};

export default TagsAutocomplete;
