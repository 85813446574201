import PropTypes from "prop-types";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";

const AlertDialog = ({
  open,
  title,
  description,
  agreeTitle,
  disagreeTitle,
  onAgree,
  onDisagree,
}) => (
  <Dialog open={open} onClose={onDisagree}>
    <DialogTitle>{title}</DialogTitle>
    <DialogContent>
      <DialogContentText>{description}</DialogContentText>
    </DialogContent>
    <DialogActions>
      <Button onClick={onDisagree}>{disagreeTitle}</Button>
      <Button onClick={onAgree} autoFocus>
        {agreeTitle}
      </Button>
    </DialogActions>
  </Dialog>
);

AlertDialog.propTypes = {
  open: PropTypes.bool,
  title: PropTypes.string,
  description: PropTypes.string,
  agreeTitle: PropTypes.string,
  disagreeTitle: PropTypes.string,
  onAgree: PropTypes.func,
  onDisagree: PropTypes.func,
};

AlertDialog.defaultProps = {
  open: false,
  title: "",
  description: "",
  agreeTitle: "",
  disagreeTitle: "",
  onAgree: () => {},
  onDisagree: () => {},
};

export default AlertDialog;
