import PropTypes from "prop-types";
import moment from "moment";
import TextField from "@mui/material/TextField";
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";

const CustomDatepicker = ({ value, handleChange, label, format, ...rest }) => (
  <DesktopDatePicker
    label={label}
    inputFormat={format}
    value={value}
    onChange={handleChange}
    renderInput={(params) => <TextField {...params} size="small" fullWidth />}
    {...rest}
  />
);

CustomDatepicker.propTypes = {
  value: PropTypes.instanceOf(moment),
  handleChange: PropTypes.func,
  label: PropTypes.string,
  format: PropTypes.string,
};

CustomDatepicker.defaultProps = {
  value: null,
  handleChange: () => {},
  label: "",
  format: "DD/MM/YYYY",
};

export default CustomDatepicker;
