import { Container } from "styles/pages/login";
import Form from "pages/login/Form";

const Login = () => (
  <Container>
    <Form />
  </Container>
);

export default Login;
