import Autocomplete from "components/Autocomplete";
import { usePost } from "hooks/usePost";
import { QUICK_BOOKS_SALES_CODE } from "constants/services";
import { PROP_TYPES, DEFAULT_STATE } from "pages/order/prop-types";
import { getSubtotal, getTax } from "pages/order/util";
import { LineItems, Total } from "styles/pages/order";

const OrderEstimationOtherCharges = ({ state, setState }) => {
  const { mutateAsync } = usePost();
  const taxInfo = getTax(state);
  const subTotal = getSubtotal(state);
  const total = subTotal + taxInfo.value;

  return (
    <LineItems>
      <Total>
        <div className="thanks">Thank you for chossing Big Oven Tees!!</div>
        <div className="table">
          <div className="row">
            <div className="col">Sub Total</div>
            <div>${subTotal}</div>
          </div>
          <div className="row">
            <div
              className="col"
              style={{ display: "flex", alignItems: "center", gap: "5px" }}
            >
              <div>Tax</div>

              {!!state?.qbSalesCodes?.length && (
                <div style={{ width: "160px" }}>
                  <Autocomplete
                    label="Sales Tax Code"
                    value={state?.estimateData?.qbSalesCode}
                    setValue={async (v) => {
                      let qbSalesCodeTaxPercentage = 0;
                      if (v?.id) {
                        const { data: response } = await mutateAsync({
                          url: `${QUICK_BOOKS_SALES_CODE}?qbCountry=${state?.printProvider?.id}`,
                          data: { taxId: v?.id },
                        });
                        qbSalesCodeTaxPercentage =
                          response?.rateData?.TaxRate?.RateValue;
                      }

                      setState({
                        ...state,
                        estimateData: {
                          ...state?.estimateData,
                          qbSalesCode: v,
                          qbSalesCodeTaxPercentage,
                        },
                      });
                    }}
                    inputValue={state?.estimateData?.qbSalesCodeInputValue}
                    setInputValue={(v) =>
                      setState({
                        ...state,
                        estimateData: {
                          ...state?.estimateData,
                          qbSalesCodeInputValue: v,
                        },
                      })
                    }
                    options={
                      state?.qbSalesCodes?.map((q) => ({
                        ...q,
                        id: q?.Id,
                        label: q?.DisplayName,
                      })) || []
                    }
                  />
                </div>
              )}
            </div>
            <div>
              ${taxInfo.value || 0} (
              {state?.estimateData?.qbSalesCodeTaxPercentage || 0}%)
            </div>
          </div>
          <div className="row">
            <div className="col">Total</div>
            <div>${total}</div>
          </div>
          <div className="row">
            <div className="col">Balance Due</div>
            <div>
              <h2 style={{ margin: 0 }}>${total}</h2>
            </div>
          </div>
        </div>
      </Total>
    </LineItems>
  );
};

OrderEstimationOtherCharges.propTypes = PROP_TYPES;

OrderEstimationOtherCharges.defaultProps = DEFAULT_STATE;

export default OrderEstimationOtherCharges;
