import { useState } from "react";
import PropTypes from "prop-types";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import OutlinedInput from "@mui/material/OutlinedInput";
import InputAdornment from "@mui/material/InputAdornment";
import IconButton from "@mui/material/IconButton";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";

const PasswordInput = ({ label, value, setValue }) => {
  const [showPassword, setShowPassword] = useState(false);

  return (
    <FormControl variant="outlined" sx={{ width: "100%" }}>
      <InputLabel size="small">{label}</InputLabel>
      <OutlinedInput
        type={showPassword ? "text" : "password"}
        size="small"
        value={value}
        onChange={(event) => setValue(event?.target?.value)}
        endAdornment={
          <InputAdornment position="end">
            <IconButton
              onClick={() => setShowPassword(!showPassword)}
              onMouseDown={(event) => event.preventDefault()}
              edge="end"
            >
              {showPassword ? <VisibilityOff /> : <Visibility />}
            </IconButton>
          </InputAdornment>
        }
        label={label}
      />
    </FormControl>
  );
};

PasswordInput.propTypes = {
  label: PropTypes.string.isRequired,
  value: PropTypes.string,
  setValue: PropTypes.func.isRequired,
};

PasswordInput.defaultProps = {
  value: "",
};

export default PasswordInput;
