import { useState } from "react";
import PropTypes from "prop-types";
import moment from "moment";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import TextareaAutosize from "@mui/material/TextareaAutosize";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import TextField from "components/TextFieldInput";
import Datopicker from "components/Datepicker";
import { MarkShipped } from "styles/components/order-card";

const initialState = {
  trackingNumber: "",
  shippedDate: moment().unix(),
  notifyClients: true,
  partialShippingMessage: "",
};

const OrderMarkShippedDialog = ({
  open,
  setOpen,
  onSubmit,
  enablePartialShippingMessage,
}) => {
  const [state, setState] = useState(initialState);
  const handleClose = () => setOpen(false);

  return (
    <Dialog open={open} onClose={handleClose} fullWidth>
      <DialogTitle>Order Shipped</DialogTitle>
      <DialogContent>
        <MarkShipped>
          <Datopicker
            label="Shipped Date"
            value={moment.unix(state?.shippedDate)}
            handleChange={(v) =>
              setState({ ...state, shippedDate: v?.unix() || null })
            }
          />

          <TextField
            label="Tracking Number"
            fullWidth
            value={state?.trackingNumber}
            handleChange={(e) =>
              setState({ ...state, trackingNumber: e?.target?.value })
            }
          />

          {enablePartialShippingMessage && (
            <TextareaAutosize
              placeholder="Partial Shipping Message"
              minRows={3}
              value={state?.partialShippingMessage}
              onChange={(e) =>
                setState({ ...state, partialShippingMessage: e?.target?.value })
              }
            />
          )}
        </MarkShipped>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose}>Cancel</Button>
        <Button
          disabled={
            !state?.trackingNumber?.trim() ||
            !state?.shippedDate ||
            (enablePartialShippingMessage
              ? !state?.partialShippingMessage?.trim()
              : false)
          }
          onClick={() => {
            onSubmit(state);
            setState(initialState);
          }}
        >
          Submit
        </Button>
      </DialogActions>
    </Dialog>
  );
};

OrderMarkShippedDialog.propTypes = {
  open: PropTypes.bool,
  setOpen: PropTypes.func,
  onSubmit: PropTypes.func,
  enablePartialShippingMessage: PropTypes.bool,
};

OrderMarkShippedDialog.defaultProps = {
  open: false,
  setOpen: () => {},
  onSubmit: () => {},
  enablePartialShippingMessage: false,
};

export default OrderMarkShippedDialog;
