import { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import AccountCircle from "@mui/icons-material/AccountCircle";
import MenuItem from "@mui/material/MenuItem";
import Menu from "@mui/material/Menu";
import Drawer from "components/Header/Drawer";
import Title from "components/Header/Title";
import { logout } from "features/user/userSlice";
import { SETTINGS } from "constants/routes";
import {
  Container,
  ProfileImage,
  StyledSettingsIcon,
} from "styles/components/header";

const Header = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const user = useSelector((state) => state?.user);
  const [anchorEl, setAnchorEl] = useState(null);

  const handleMenu = (event) => setAnchorEl(event.currentTarget);
  const handleClose = () => setAnchorEl(null);

  return (
    <Container position="static">
      <Toolbar sx={{ height: "40px" }}>
        <Drawer />
        <Title />

        <IconButton
          size="large"
          color="inherit"
          onClick={() => navigate(SETTINGS)}
        >
          <StyledSettingsIcon />
        </IconButton>
        <div>
          <IconButton size="large" onClick={handleMenu} color="inherit">
            {user?.profileImg ? (
              <ProfileImage src={user?.profileImg} alt="profile" />
            ) : (
              <AccountCircle />
            )}
          </IconButton>
          <Menu
            anchorEl={anchorEl}
            anchorOrigin={{
              vertical: "top",
              horizontal: "right",
            }}
            keepMounted
            transformOrigin={{
              vertical: "top",
              horizontal: "right",
            }}
            open={Boolean(anchorEl)}
            onClose={handleClose}
          >
            <MenuItem
              onClick={() => {
                dispatch(logout());
                handleClose();
              }}
            >
              Logout
            </MenuItem>
          </Menu>
        </div>
      </Toolbar>
    </Container>
  );
};

export default Header;
