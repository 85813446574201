import { useState } from "react";
import PropTypes from "prop-types";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import TextareaAutosize from "@mui/material/TextareaAutosize";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import { usePost } from "hooks/usePost";
import TagsAutocomplete from "components/TagsAutocomplete";
import {
  CONFLUENCES_USERS,
  CONFLUENCE_NOTIFICATIONS,
} from "constants/services";
import { MarkShipped } from "styles/components/order-card";

const initialState = {
  to: [],
  cc: [],
  attentionMessage: "",
};

const AttentionMessageDialog = ({ open, setOpen, order }) => {
  const [state, setState] = useState(initialState);
  const handleClose = () => setOpen(false);
  const { mutateAsync } = usePost();

  return (
    <Dialog open={open} onClose={handleClose} fullWidth>
      <DialogTitle>Send Attention Message</DialogTitle>
      <DialogContent>
        <MarkShipped>
          <TagsAutocomplete
            optionApiKey="email"
            apiEndPoint={CONFLUENCES_USERS}
            value={state?.to}
            setValue={(to) => setState({ ...state, to })}
            label="to"
          />

          <TagsAutocomplete
            optionApiKey="email"
            apiEndPoint={CONFLUENCES_USERS}
            value={state?.cc}
            setValue={(cc) => setState({ ...state, cc })}
            label="cc"
          />

          <TextareaAutosize
            placeholder="Attention Message"
            minRows={3}
            value={state?.attentionMessage}
            onChange={(e) =>
              setState({ ...state, attentionMessage: e?.target?.value })
            }
          />
        </MarkShipped>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose}>Cancel</Button>
        <Button
          disabled={
            !state?.attentionMessage?.trim() ||
            !state?.to?.length ||
            !state?.cc?.length
          }
          onClick={async () => {
            const payload = {
              orderNumber: order?.orderNumber,
              vendorName: order?.vendorName,
              attentionMessage: state?.attentionMessage,
              to: state?.to?.map((v) => v?.value),
              cc: state?.cc?.map((v) => v?.value),
              notificationType: "Attention_Message",
            };

            await mutateAsync({ url: CONFLUENCE_NOTIFICATIONS, data: payload });
            handleClose();
          }}
        >
          Submit
        </Button>
      </DialogActions>
    </Dialog>
  );
};

AttentionMessageDialog.propTypes = {
  open: PropTypes.bool,
  setOpen: PropTypes.func,
  order: PropTypes.shape({
    orderNumber: PropTypes.string,
    vendorName: PropTypes.string,
  }),
};

AttentionMessageDialog.defaultProps = {
  open: false,
  setOpen: () => {},
  order: {},
};

export default AttentionMessageDialog;
