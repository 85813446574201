/* eslint-disable */

import styled from "styled-components";

const Container = styled.div`
  .rdw-editor-toolbar {
    padding: 0;
    border: none;
    margin-bottom: 0;
  }
  .rdw-editor-wrapper {
    border: 1px solid #efefef;
    border-radius: 6px;
  }
  .rdw-editor-main {
    border-top: 1px solid #efefef;
    padding: 5px;
  }
`;

export { Container };
