const getSubtotal = (state) => {
  const editedLineItems = state?.editedLineItems;
  const editedOtherCharges = state?.editedOtherCharges;

  let subTotal = 0;

  for (let i = 0; i < editedLineItems?.length; i += 1) {
    subTotal +=
      Number(editedLineItems[i]?.rate || 0) *
      Number(editedLineItems[i]?.qty || 0);
  }

  for (let i = 0; i < editedOtherCharges?.length; i += 1) {
    subTotal +=
      Number(editedOtherCharges[i]?.chargeAmount || 0) *
      Number(editedOtherCharges[i]?.qty || 0);
  }

  return Number(subTotal);
};

const getTax = (state) => {
  const taxPercentage = Number(
    state?.estimateData?.qbSalesCodeTaxPercentage || 0
  );

  if (!taxPercentage) return { value: 0 };

  const subTotal = getSubtotal(state);

  return { value: Number(Number((subTotal * taxPercentage) / 100).toFixed(2)) };
};

export { getSubtotal, getTax };
