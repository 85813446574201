import PropTypes from "prop-types";
import Pagination from "@mui/material/Pagination";

const CustomPagination = ({ count, page, handleChange }) => (
  <Pagination
    color="primary"
    count={count}
    page={page}
    onChange={handleChange}
    sx={{ display: "flex", justifyContent: "flex-end" }}
  />
);

CustomPagination.propTypes = {
  count: PropTypes.number,
  page: PropTypes.number,
  handleChange: PropTypes.func.isRequired,
};

CustomPagination.defaultProps = {
  count: 0,
  page: 0,
};

export default CustomPagination;
