import { forwardRef } from "react";
import PropTypes from "prop-types";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";

const Alert = forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const CustomSnackbar = ({ message, onClose, ...rest }) => (
  <Snackbar open={!!message} autoHideDuration={6000} onClose={onClose}>
    <Alert onClose={onClose} {...rest}>
      {message}
    </Alert>
  </Snackbar>
);

CustomSnackbar.propTypes = {
  message: PropTypes.string,
  onClose: PropTypes.func.isRequired,
};

CustomSnackbar.defaultProps = {
  message: "",
};

export default CustomSnackbar;
