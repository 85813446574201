import styled from "styled-components";
import Container from "@mui/material/Container";
import Divider from "@mui/material/Divider";

export const StyledContainer = styled(Container)``;

export const Row = styled.div`
  display: flex;
  margin-top: 16px;
  flex-direction: column;
  flex: ${(props) => props?.flex || 0};
  align-items: center;
`;

export const Col = styled.div`
  display: flex;
  gap: 40px;
`;

export const Form = styled.div`
  width: 100%;

  .MuiFormControl-root {
    width: 100%;
    margin-bottom: 20px;
  }
`;

export const Actions = styled.div`
  display: flex;
  justify-content: flex-end;
  gap: 20px;
`;

export const AvatarWrapper = styled.div`
  position: relative;
  button {
    position: absolute;
    bottom: 0;
    right: 0;
  }
`;

export const StyledDivider = styled(Divider)`
  &.MuiDivider-root {
    width: 100%;
    margin: 20px 0;
    border-color: #000;
  }
`;

export const HistoryWrapper = styled.div`
  margin-top: 60px;
  position: relative;

  .pagination {
    position: absolute;
    top: -18px;
    right: 0;
  }

  .MuiStepContent-root,
  .MuiStepConnector-root {
    margin-left: 19px;

    .MuiTypography-root {
      color: #a1a1a1;
      font-size: 12px;
    }
  }
`;

export const StepperLabel = styled.div`
  display: flex;
  justify-content: space-between;

  .MuiTypography-root {
    color: #a1a1a1;
    font-size: 12px;
  }
`;
