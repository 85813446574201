import moment from "moment";
import Chip from "@mui/material/Chip";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { DEFAULT_STATE, PROP_TYPES } from "pages/order-request/prop-types";

const getStatusStyles = (status) => {
  const styles = { color: "white", fontWeight: "bold" };
  switch (status) {
    case "OPEN":
      return { ...styles, background: "red" };

    case "PENDING":
      return {
        ...styles,
        background: "rgb(102, 178, 255)",
      };

    case "SOLVED":
      return {
        ...styles,
        background: "#000",
      };

    default:
      return { ...styles };
  }
};

const OrderRequestsTable = ({ state, setState }) => {
  const orderRequests = state?.orderRequests?.data;
  const rows = orderRequests?.map((o) => ({ ...o }));

  return (
    <TableContainer sx={{ marginTop: "10px" }}>
      <Table sx={{ minWidth: 650 }} aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell />
            <TableCell>Request ID</TableCell>
            <TableCell>Subject</TableCell>
            <TableCell>Requester</TableCell>
            <TableCell>Requested</TableCell>
            <TableCell>Assignee</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {rows.map((row) => (
            <TableRow
              key={row.requestId}
              sx={{ cursor: "pointer" }}
              onClick={() => setState({ ...state, selectedRequest: row })}
            >
              <TableCell>
                <Chip label={row?.status} sx={getStatusStyles(row?.status)} />
              </TableCell>
              <TableCell>{row.requestId}</TableCell>
              <TableCell>{row.subject}</TableCell>
              <TableCell>{row.email}</TableCell>
              <TableCell>
                {moment.unix(row?.createdDate).format("MMM DD, YYYY")}
              </TableCell>
              <TableCell>Tru-Trac Support</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

OrderRequestsTable.propTypes = PROP_TYPES;

OrderRequestsTable.defaultProps = DEFAULT_STATE;

export default OrderRequestsTable;
