const openCloudinaryWidget = (folder, clientAllowedFormats, cb) => {
  const myWidget = window.cloudinary.createUploadWidget(
    {
      cloudName: "big-oven-tees-inc",
      show_powered_by: false,
      uploadPreset: "tnedst8q",
      sources: ["local", "camera", "url"],
      folder,
      clientAllowedFormats,
      multiple: false,
    },
    (error, result) => {
      if (!error && result && result.event === "success") {
        cb(result?.info);
        myWidget.close();
      }
    }
  );
  myWidget.open();
};

export default openCloudinaryWidget;
